:root {
  --width-container: 80%;
  --primary-color: #3C50E0
}

* {
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

.over {
  width: 100%;
  position: relative;
}

.container {
  width: var(--width-container);
  margin: 0 auto;
}

.over--background {
  background-color: #F3F3F3;
}

.over__background--header {
  background-color: #F3F3F3;
  position: fixed;
  z-index: 10;
}

.select {
  width: 225px;
  height: 43px;
  border-radius: 10px;
  text-align-last: center;
  font-size: 14px;
  text-transform: uppercase;
  background-color: #1C2434;
  color: #FFFFFF;
  border: none;

  background-image: url('../assets/img/arrow-select.png');
  background-size: 14px 6px;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 20px);
  background-position-y: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select:focus-visible {
  outline: none;
}

.button {
  background-color: var(--primary-color);
  height: 45px;
  color: #fff;
  border: none;
  padding: 0 50px;
  font-weight: 700;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
}

.button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.button__contact {
  margin: 30px 0 0 0;
}

.button__exploit {
  margin-top: 100px;
}

.button__price--exit {
  margin: 0px 15px 0 0;
}

.title {
  font-size: 40px;
  font-weight: 700;
  max-width: 770px;
  text-align: center;
  margin: 0 0 15px 0;
  text-transform: uppercase;
}

.description {
  font-weight: 500;
  font-size: 16px;
  max-width: 770px;
  text-align: center;
  text-transform: uppercase;
}

.textLeft {
  text-align: left;
}

.input {
  height: 45px;
  border: none;
  background-color: rgba(243, 243, 243, 1);
  padding: 0 15px;
  width: 250px;
}

.displayFlex {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.div--center {
  text-align: center;
}

.header__menu {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.header__menu--list {
  display: flex;
  justify-content: space-between;
}

.header__menu--listMobi {
  position: absolute;
  right: 0;
  flex-direction: column;
  background-color: #F3F3F3;
  width: 100%;
  top: 70px;
}

.header__menu--item {
  font-weight: 700;
  font-size: 15px;
  margin: 0 0 0 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
  text-transform: uppercase;
}
.header__menu-logo{
  display: flex;
}
.header__menu-info{
  margin-left: 15px;
}
.header__menu-info a{
  color: var(--primary-color);
  font-weight: bold;
  text-decoration: none;
}
.header__menu-info a:hover{
}
.zalo_floating{
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.zalo_floating a{
  padding: 10px;
    display: block;
}
.zalo_floating img{
  width: 50px;
}
.header__menu-logo{
  display: flex;
}
.header__logo {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.header__introduce {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 100px 0 0 0;
}

.header__introduce--title {
  width: 770px;
  text-align: center;
  margin: 0 0 15px 0;
}

.header__introduce--img {
  width: 100%;
  margin: 50px 0 -4px 0;
  object-fit: contain;
}

.solution__header {
  padding: 100px 0 120px 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.slider__view {
  padding: 75px 0 60px;
  width: 100%;
}

.slider__item {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

.slider__avatar {
  width: 130px;
  height: 130px;
  object-fit: contain;
}

.slider__comment {
  font-size: 16px;
  font-weight: 400;
  padding: 25px 0;
}

.slider__name {
  font-weight: 700;
  font-size: 20px;
}

.slider__rate {
  font-size: 16px;
  padding: 15px 0 10px;
  color: rgba(255, 128, 0, 1);
}

.slick-dots li button:before {
  font-size: 12px !important;
}

.solution__show {
  display: flex;
  justify-content: space-between;
  padding: 0 0 130px 0;
}

.solution__show--item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32%;
}

.solution__img {
  width: 70px;
  height: 70px;
  object-fit: contain;
  margin: 0 0 15px 0;
}

.solution__item--title {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 15px 0;
  text-transform: uppercase;
  text-align: center;
}

.solutin__item--description {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.img--macbook {
  width: 650px;
  height: 350px;
  object-fit: contain;
}

.info__view {
  height: 350px;
  padding: 145px 0 0 0;
}

.info__item {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info__img {
  width: 51px;
  height: 72px;
  object-fit: contain;
}

.info--title {
  font-size: 36px;
  font-weight: 500;
  padding: 15px 0;
}

.info--description {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.info__phone {
  padding: 75px 0 80px;
  background-image: url('../assets/img/bgPhone.png');
  background-repeat: no-repeat;
  background-size: 1130px 100%;
  background-position: 250%;
}

.package__view {
  box-shadow: 3px 8px 12px rgba(0, 0, 0, 0.25);
  margin-bottom: 105px;
  width: 400px;
  height: 510px;
  padding: 100px 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  transition: 0.2s;
}

.package__view:hover {
  scale: 1.01;
}

.package__title {
  font-weight: 700;
  font-size: 20px;
}

.package__title--header {
  text-align: left;
  padding-top: 160px;
}

.package__description {
  font-weight: 500;
  font-size: 16px;
}

.list__trademark {
  padding: 65px 0;
}

.footer__title {
  font-weight: 700;
  font-size: 32px;
}

.footer__description {
  font-weight: 400;
  font-size: 16px;
}

.footer__list {
  display: flex;
  justify-content: space-between;
  padding: 30px 0 0 0;
  width: 350px;
}

.footer__list__item {
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
  text-transform: uppercase;
}

.footer {
  padding: 55px 0 95px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.package__over {
  width: 900px;
}

.info__over {
  padding: 110px 0 90px;
}

.info__view:nth-child(2) {
  padding: 70px 0 145px;
}

.contact__over {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 13px -3px rgba(0, 0, 0, 0.07);
}

.contact__header {
  height: 37px;
  border-radius: 10px 10px 0 0;
  background-color: #1C2434;
  color: #FFF;
  text-transform: uppercase;
  line-height: 37px;
  padding: 10px 0 10px 30px;
  display: flex;
  justify-content: center;
  font-weight: 600;
}

.contact__content {
  padding: 80px 25px;
  display: flex;
  justify-content: space-between;
}

.contact__content--left {
  display: flex;
  flex-direction: column;
  max-width: 350px;
}

.contact__content--title {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 45px;
  text-transform: uppercase;
}

.contact__content--des {
  color: #1C2434;
  margin: 0 0 12px 0;
  font-weight: 500;
}

.contact__content--right {
  max-width: 660px;
  flex-grow: 1;
}

.contact__content--input {
  background-color: #1C2434;
  color: #FFF;
  border-radius: 10px;
  margin: 0 0 10px;
  padding: 8px 25px;
  height: 43px !important;
  width: 100%;
  box-sizing: border-box;
}

.contact__content--input::placeholder {
  color: #FFF;
  opacity: 0.8;
}

.contact__content--textarea {
  width: 100%;
  height: 130px;
  border-radius: 10px;
  background-color: #1C2434;
  color: #FFF;
  padding: 8px 25px;
  box-sizing: border-box;
  margin: 0 0 10px;
}

.contact__content--textarea::placeholder {
  color: #FFF;
  opacity: 0.8;
}

.input--hotel {
  background-color: #1C2434;
  color: #FFF;
  height: 43px;
  border-radius: 10px;
  padding: 0 10px;
  margin: 0 0 0 10px;
  border: none;
  text-align: center;
  box-sizing: border-box;
}

.input--hotel:focus-visible {
  outline: none;
}

.price__title {
  font-size: 16px;
  font-weight: 700;
  margin: 50px 0 30px 0;
}

.price__des {
  text-transform: uppercase;
  font-size: 16px;
  margin: 0 0 15px 0;
}

.price__bold {
  font-weight: 700;
}

.price__table--title {
  margin: 10px 0 0 0;
  font-size: 14px;
  font-weight: 500;
}

/* table */
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.contact__view--icon {
  margin-top: 40px;
}

.contact__icon>img {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.contact__icon:not(:last-child) {
  margin: 0 15px 0 0;
}

@keyframes appear {
  from {
    margin: -15px 0 3px 0;
  }

  to {
    margin: -8px 0 10px 0;
  }
}

.content__warning {
  color: red;
  font-size: 13px;
  animation-name: appear;
  animation-duration: 1s;
  margin: -8px 0 10px 0;
  width: 100%;
}

.footer__custom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0 0;
}

@media only screen and (max-width: 1200px) {
  :root {
    --width-container: 85%;
  }

  .title {
    font-size: 36px;
  }

  .description {
    font-size: 14px;
  }

  .img--macbook {
    width: 600px;
    height: 300px;
  }

  .contact__content--right {
    max-width: 540px;
  }

}

@media only screen and (max-width: 960px) {
  :root {
    --width-container: 90%;
  }

  .title {
    font-size: 34px;
  }

  .header__introduce {
    padding: 80px 0 0 0;
  }

  .solution__header {
    padding: 50px 0;
  }

  .img--macbook {
    width: 350px;
    height: 300px;
  }

  .solution__show {
    padding: 0 0 70px 0;
  }

  .info__view {
    padding: 70px 0 0 0;
  }

  .package__over {
    width: 550px;
  }

  .package__view {
    width: 250px;
    height: 400px;
  }

  .contact__content {
    flex-direction: column;
    padding: 30px 25px;
  }

  .contact__content--left {
    width: 100%;
    align-items: center;
    max-width: 100%;
  }

  .contact__content--title {
    margin: 0 0 15px;
  }

  .contact__view--icon {
    margin: 10px 0 20px;
  }
}

@media only screen and (max-width: 550px) {
  :root {
    --width-container: 95%;
  }

  .header__menu--item {
    padding: 10px 0;
  }

  .header__introduce {
    padding: 105px 0 0 0;
  }

  .title {
    font-size: 32px;
  }

  .description {
    font-size: 14px;
  }

  .img--macbook {
    width: 100%;
    height: 150px;
  }

  .solution__show {
    flex-direction: column;
  }

  .solution__show--item {
    width: 100%;
    padding: 10px 0 0 0;
  }

  .info__view {
    flex-direction: column;
  }

  .info__view:last-child {
    flex-direction: column-reverse;
  }

  .info__item {
    width: 50%;
    padding-top: 25px;
  }

  .info__over {
    flex-wrap: wrap;
    padding: 55px 0 45px;
  }

  .package__over {
    flex-direction: column;
    width: 100%;
  }

  .package__view {
    width: 100%;
    padding: 50px 0 25px;
  }

  .package__title--header {
    padding-top: 50px;
  }

  .footer__list {
    width: 300px;
  }

  .footer__title {
    font-size: 26px;
  }

  .footer__description {
    font-size: 14px;
  }

  .info__view--description {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .button__exploit {
    margin-top: 10px;
  }

  .info__view:nth-child(2) {
    padding: 165px 0 50px;
  }

  .select {
    width: 100%;
    margin: 0 0 10px 0;
  }

  .input--hotel {
    width: 100%;
    margin: 0;
  }

}
